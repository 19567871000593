import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { ContactShadows, OrbitControls } from "@react-three/drei";

import Model from "./Model";

const Ball = ({ isOpen, setIsOpen, current, setCurrent }) => {
  return (
    <Canvas>
      <Suspense fallback={"..."}>
        <ambientLight />

        <spotLight
          castShadow
          angle={0.1}
          penumbra={1}
          intensity={0.9}
          position={[10, 15, 10]}
        />

        <Model
          isOpen={isOpen}
          current={current}
          setIsOpen={setIsOpen}
          setCurrent={setCurrent}
        />

        <ContactShadows
          far={4}
          blur={2.5}
          scale={10}
          opacity={0.75}
          position={[0, -2, 0]}
        />

        <OrbitControls
          enablePan={false}
          enableZoom={false}
          enableRotate={true}
        />
      </Suspense>
    </Canvas>
  );
};

export default Ball;
