import { useEffect, useState } from "react";

import LanguageSwitcher from "../Language/LanguageSwitcher";

import "./header.css";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [languages, setLanguages] = useState();

  const getData = async () => {
    try {
      const response = await fetch("constants/languages.json");
      const data = await response.json();

      setLanguages(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="HeaderWrapper">
      <img src="/samuel_haas_logo.png" alt="samuel_haas_logo" />

      <div className="LanguagesWrapper">
        <svg
          width="28px"
          height="28px"
          viewBox="0 0 24 24"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="languageIconTitle"
          stroke="#fff"
          strokeWidth="1"
          strokeLinecap="square"
          strokeLinejoin="miter"
          fill="none"
          color="#ffffff"
          cursor="pointer"
          onClick={() => setOpen(!open)}
        >
          <title id="languageIconTitle">Language</title>
          <circle cx="12" cy="12" r="10" />
          <path
            strokeLinecap="round"
            d="M12,22 C14.6666667,19.5757576 16,16.2424242 16,12 C16,7.75757576 14.6666667,4.42424242 12,2 C9.33333333,4.42424242 8,7.75757576 8,12 C8,16.2424242 9.33333333,19.5757576 12,22 Z"
          />
          <path strokeLinecap="round" d="M2.5 9L21.5 9M2.5 15L21.5 15" />{" "}
        </svg>

        {open && (
          <LanguageSwitcher
            open={open}
            languages={languages}
            setOpen={(v) => setOpen(v)}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
