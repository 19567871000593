import { useTranslation } from "react-i18next";

import "./Modal.css";

const Modal = ({ setIsOpen, current }) => {
  const { t, i18n } = useTranslation();

  // Get the title and description
  const title = `ball.${current}.title`;
  const description = `ball.${current}.description`;

  const hasTitle = i18n.exists(title) && t(title) !== "";
  const hasDescription = i18n.exists(description) && t(description) !== "";

  // Get the button link and label
  const btnLink = `ball.${current}.button.link`;
  const btnLabel = `ball.${current}.button.label`;

  const hasBtnLink = i18n.exists(btnLink) && t(btnLink) !== "";
  const hasBtnLabel = i18n.exists(btnLabel) && t(btnLabel) !== "";

  // If there is no title and no description, close the modal
  if (!hasTitle && !hasDescription) {
    setIsOpen(false);
    return;
  }

  return (
    <>
      <div className="darkBG" onClick={() => setIsOpen(false)} />

      <div className="centered">
        <div className="modal">
          <div className="modalHeader">
            <h5 className="heading">{hasTitle && t(title)}</h5>
          </div>

          <div className="modalContent">
            {hasDescription && t(description)}

            {hasBtnLink && hasBtnLabel && (
              <a
                target="_blank"
                className="linkBtn"
                href={t(btnLink)}
                rel="noreferrer"
              >
                {t(btnLabel)}
              </a>
            )}
          </div>

          <div className="ModalFooter">
            <button className="closeBtn" onClick={() => setIsOpen(false)}>
              {t("close")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
