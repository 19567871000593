import cookies from "js-cookie";

import i18n from "../../i18n";

import "./LanguageSwitcher.css";

const Language = ({ open, setOpen = () => {}, languages }) => {
  const currentLangCode = cookies.get("i18next") || "en";

  return (
    open &&
    languages?.length > 0 && (
      <div className="LanguageWrapper">
        <ul>
          {languages?.map?.(({ label, code }) => {
            return (
              <li
                key={code}
                onClick={() => {
                  setOpen(false);
                  i18n.changeLanguage(code);
                }}
                className={(code === currentLangCode && "active") || ""}
              >
                {label}
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};

export default Language;
