import { useState } from "react";

import Ball from "./components/Ball/Ball";
import Header from "./components/header/Header";
import Modal from "./components/Modal/Modal";

import "./App.css";

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(null);

  return (
    <>
      <Header />

      <Ball
        isOpen={isOpen}
        current={current}
        setIsOpen={setIsOpen}
        setCurrent={setCurrent}
      />

      {isOpen && current && <Modal setIsOpen={setIsOpen} current={current} />}
    </>
  );
};

export default App;
