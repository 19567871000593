import { useRef } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { useTranslation } from "react-i18next";
import { Decal, useGLTF, useTexture } from "@react-three/drei";

const Model = ({ isOpen, setIsOpen, setCurrent }) => {
  const ref = useRef();
  const meshRefs = useRef([]);

  const { t, i18n } = useTranslation();

  let { nodes } = useGLTF("assets/ball/ball.gltf");

  // Get the photos
  const items = Object.entries(nodes).reduce((acc, [, b]) => {
    const photo = `ball.${b?.name}.photo`;

    if (i18n.exists(photo) && t(photo) !== "") {
      console.log({ photo });
      return { ...acc, [b?.name]: t(photo) };
    }

    return acc;
  }, {});

  // Load the textures
  const textures = useTexture(items, (textures) => {
    textures?.forEach?.((t) => (t.flipY = false));
  });

  // Get the nodes and transform them into an array
  nodes = Object.entries(nodes).map((node) => {
    const [key, b] = node;

    let photo = null;

    const photoPath = `ball.${b?.name}.photo`;

    const hasImage = i18n.exists(photoPath) && t(photoPath) !== "";

    if (hasImage) photo = textures[b?.name];

    const updatedB = { ...b, photo };

    return [key, updatedB];
  });

  const handleChangeColor = (e, index, color) => {
    e.stopPropagation();
    meshRefs.current[index].material.color.set(color);
  };

  const handleClick = (e, b) => {
    e.stopPropagation();

    const title = `ball.${b?.name}.title`;
    const description = `ball.${b?.name}.description`;

    const hasTitle = i18n.exists(title) && t(title) !== "";
    const hasDescription = i18n.exists(description) && t(description) !== "";

    console.log(b?.name);

    if (!hasTitle && !hasDescription) return;

    setIsOpen(true);
    setCurrent(b?.name);
  };

  const getCenterPosition = (position) => {
    const boundingBox = new THREE.Box3().setFromBufferAttribute(position);

    const center = new THREE.Vector3();
    boundingBox.getCenter(center);

    return center;
  };

  useFrame(() => {
    if (!isOpen) ref.current.rotation.y += 0.005;
  });

  return (
    <group dispose={null} scale={2} ref={ref}>
      {nodes.map((node, i) => {
        const [, b] = node;

        const hasPhoto = i18n.exists(`ball.${b?.name}.photo`);

        return (
          <mesh
            key={i}
            geometry={b.geometry}
            material={b?.material}
            rotation={[Math.PI, 0, 0]}
            onClick={(e) => handleClick(e, b)}
            material-color={b?.material?.color}
            ref={(ref) => (meshRefs.current[i] = ref)}
            onPointerOver={(e) => handleChangeColor(e, i, "#D0AD68")}
            onPointerOut={(e) => handleChangeColor(e, i, b?.material?.color)}
          >
            <meshBasicMaterial color={b?.material?.color} />

            {hasPhoto && b?.photo && (
              <Decal
                scale={0.5}
                map={b?.photo}
                map-anisotropy={86}
                position={getCenterPosition(b?.geometry?.attributes?.position)}
              />
            )}
          </mesh>
        );
      })}
    </group>
  );
};

export default Model;
